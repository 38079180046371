import React from 'react';

import Layout from '../components/layout';

const About = () => (
  <Layout>
    <p>Nothing here yet ¯\_(ツ)_/¯</p>
  </Layout>
);

export default About;
